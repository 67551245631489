<style>
	.footer {
		padding: 0;
		height: 100% !important;
		background: #001529 no-repeat bottom center;
		background-size: 100% auto;
	}
	.wrap {
		margin: 0 auto;
		width: 1200px;
		min-width: 1200px;
	}
	.login-bg {
		width: 100%;
		height: 100%;
		background: transparent;
	}

	.login-bg .ant-input,
	.login-bg .ant-input:focus {
		border: none;
		border-radius: 0;
		border-bottom: 1px solid #1a91ff !important;
		background: transparent;
		box-shadow: none !important;
		color: #1a91ff;
	}
	.login-card input::-webkit-input-placeholder {
		color: #1a91ff;
	}
	.login-bg .ant-input i {
		font-size: 22px;
		color: #1a91ff;
	}
	.login-card {
		background-size: 100% 100%;
		padding: 0px 0px;
	}
	.bg {
		background: url(../assets/bg.jpg) no-repeat left top;
		background-size: 100% auto;
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
</style>
<template>
	<div class="login-bg" style="padding:15px">
		<a-card>
			<h1 style="font-weight:400;font-size:22px;margin: 20px 0 20px 0;text-align:left;color:#1a91ff">欢迎登录</h1>
			<a-alert v-if="loginError" type="error" :message="loginErrorMessage" :closable="false" showIcon style="margin: 0 0 15px 0;" />
			<p v-else style="height:38px;line-height:38px;margin: 0 0 15px 0;">&nbsp;&nbsp;&nbsp;</p>
			<a-form-model class="login-card" ref="login_ref" :model="login_data" :rules="login_rules">
				<a-form-model-item prop="name">
					<a-input type="text" v-model="login_data.name" placeholder="请输入考生姓名">
						<i class="iconfont icon-shoujihao" slot="prefix" style="color:#1a91ff" />
					</a-input>
				</a-form-model-item>
				<a-form-model-item prop="pwd">
					<a-input type="password" v-model="login_data.pwd" placeholder="请输入考生密码">
						<i class="iconfont icon-mima" slot="prefix" style="color:#1a91ff" />
					</a-input>
				</a-form-model-item>
			</a-form-model>
			<a-button type="primary" round style="margin-top:70px;width:100%;background:linear-gradient(to right,#36a1ff,#36a1ff);border:0" @click="login('login_ref')">登 录</a-button>
		</a-card>
	</div>
</template>
<script>
	import request from "../plugins/axios.js";
	import VideoBg from "vue-videobg";
	export default {
		data() {
			return {
				//登录报错信息
				loginError: false,
				loginErrorMessage: "",
				//登录表单
				login_data: {
					name: "",
					pwd: "",
				},
				//登录表单验证规则
				login_rules: {
					name: [{ required: true, message: "请输入考生姓名" }],
					pwd: [{ required: true, message: "请输入身份证号" }],
				},
			};
		},
		components: { VideoBg },
		mounted() {
			//设置背景动画播放速度
			let obj = document.getElementById("bg").childNodes;
			obj[0].playbackRate = 0.75;
		},
		methods: {
			go_login() {
				this.$router.push("login");
			},
			login(form_ref) {
				this.$refs[form_ref].validate((vaild, fields) => {
					if (vaild) {
						//提交表单
						this.loginError = false;
						this.loginErrorMessage = "";
						request({
							url: "/Login/index",
							data: {
								name: this.login_data.name,
								pwd: this.login_data.pwd,
							},
						}).then(
							(ret) => {
								if (ret.code == 1) {
									window.localStorage.setItem("token", ret.data.token);
									window.localStorage.setItem("name", ret.data.name);
									this.$router.push({
										path: "/exam/" + this.$route.query.uuid,
									});
									return true;
								} else {
									this.loginError = true;
									this.loginErrorMessage = ret.msg;
								}
							},
							(err) => {
								this.loginError = true;
								this.loginErrorMessage = err;
							}
						);
					}
					return false;
				});
			},
		},
	};
</script>